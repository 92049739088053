<template>
    <v-sheet
      color="yellow lighten-3"
      class="fill-height"
    >
      <div class="yellow lighten-1 d-flex" :class="{'justify-space-between': showAdd, 'justify-end': !showAdd} ">
        <v-btn
          v-if="showAdd"
          text icon
          small
          @click="addClick"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
        <v-btn
          text icon
          small
          @click="trashClick"
        >
          <v-icon>fas fa-trash-alt</v-icon>
        </v-btn>
      </div>
      <v-progress-linear :indeterminate="loading" color="yellow darken-2"></v-progress-linear>
      <div class="px-3">
        <v-textarea
          ref="input"
          v-model="text"
          :auto-grow="false"
          placeholder="Start typing to enter a new note"
          @blur="save"
          single-line
          persistent-hint
          :hint="loading ? '...saving' : lastSaved ? `Saved: ${lastSaved}` : null"
          :autofocus="!id && false"
        ></v-textarea>
      </div>
    </v-sheet>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: ['note', 'loading', 'id', 'dtModified', 'showAdd'],
  data () {
    return {
      text: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    lastSaved () {
      return this.dtModified ? moment(this.dtModified).format('LLLL') : null
    }
  },
  methods: {
    onChange: debounce(function () {
      this.save()
    }, 750),
    save () {
      this.$emit('save', this.text)
    },
    trashClick () {
      this.$emit('trash-click')
    },
    addClick () {
      this.$emit('add-click')
    }
  },
  watch: {
    text: 'onChange'
  },
  mounted () {
    this.text = this.note
  }
}
</script>
