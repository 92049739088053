<template>
  <v-card :flat="embed">
    <v-toolbar color="color1 color1Text--text" v-if="!embed">
      <v-toolbar-title>Your Notes</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          v-if="!hasNew"
          small fab
          color="color2 color2Text--text"
          @click="newNote"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-toolbar>
    <v-card-text :class="{'pa-0': embed}">
      <v-data-iterator
        :items="notes"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row dense>
            <v-col
              v-for="(note, i) in props.items"
              :key="note.id"
              cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" :xl="embed ? 12 : 3"
            >
              <user-player-note
                :showAdd="embed && i === 0 && !hasNew"
                @add-click="newNote"
                :note="note"
                @delete-me="deleteMe(i)"
              ></user-player-note>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <div class="text-right" v-if="!embed">
        <v-btn
          color="color3" x-small
          fab text icon
          @click.stop="getNotes"
          :loading="loading"
        >
          <v-icon>fas fa-redo</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import UserPlayerNote from '@/components/Player/UserPlayerNote'

export default {
  props: ['player', 'embed'],
  data () {
    return {
      loading: false,
      notes: []
    }
  },
  computed: {
    ...mapGetters(['user']),
    hasNew () {
      return !!this.notes.find(f => f.id === 0)
    }
  },
  methods: {
    onBlocked (msg) {
      this.$emit('blocked', msg)
    },
    newNote () {
      this.notes.unshift({
        id: 0,
        note: null,
        playerProfileId: this.player.ppId
      })
    },
    getNotes () {
      this.loading = true
      this.$VBL.user.getPlayerNotes(this.player.ppId)
        .then(r => {
          this.notes = r.data
          this.notes.length === 0 && this.newNote()
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    deleteMe (i) {
      if (this.notes.length > i) {
        this.notes.splice(i, 1)
      }
      this.notes.length === 0 && this.newNote()
    }
  },
  components: {
    UserPlayerNote
  },
  mounted () {
    this.getNotes()
  }
}
</script>

<style scoped>
>>> .v-skeleton-loader__image {
  height: 100%;
}
</style>
