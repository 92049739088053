<template>
  <v-window v-model="window" touchless>
    <v-window-item :value="0">
      <post-it
        :note="note.note"
        :loading="loading"
        @save="save"
        :id="note.id"
        :showAdd="showAdd"
        :dtModified="note.dtModified"
        @trash-click="onTrashClick"
        @add-click="onAddClick"
      ></post-it>
    </v-window-item>
    <v-window-item :value="1">
      <v-card class="text-center">
        <v-card-text class="title">
          Delete this note?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click="doDelete">yes</v-btn>
          <v-btn color="error" @click="window=0">no</v-btn>
        </v-card-actions>
      </v-card>
    </v-window-item>
  </v-window>
</template>

<script>
import PostIt from '@/components/Utils/PostIt'

export default {
  props: ['note', 'showAdd'],
  data () {
    return {
      loading: false,
      window: 0
    }
  },
  methods: {
    save (newNote) {
      if (newNote !== this.note.note) {
        this.loading = true
        this.note.note = newNote
        this.$VBL.user.postPlayerNotes(this.note.playerProfileId, this.note)
          .then(r => {
            this.note.id = r.data.id
            this.note.dtModified = r.data.dtModified
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      }
    },
    onTrashClick () {
      if (this.note.id) {
        this.window = 1
        return
      }
      this.$emit('delete-me')
    },
    onAddClick () {
      this.$emit('add-click')
    },
    doDelete () {
      if (this.note.id) {
        this.loading = true
        this.$VBL.user.deleteNote(this.note.id)
          .then(r => {
            this.$emit('delete-me')
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      } else {
        this.$emit('delete-me')
      }
    }
  },
  components: {
    PostIt
  }
}
</script>
